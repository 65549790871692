@import 'styles/layers.scss';

@layer uikit {
  .content {
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: auto;
    padding-bottom: 5px;
    display: grid !important;
    grid-template-columns: repeat(2, 532px);
  }
}