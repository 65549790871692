@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    background-color: var(--black4);
    border: none;
    &:hover {
      background-color: var(--black3);
    }
  }
}