@import 'styles/layers.scss';

@layer uikit {
  .btn {
    background-color: var(--black4);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100%;
    color: inherit;
    user-select: none;
    &:after {
      content: none;
      display: none !important;
    }
    &:before {
      content: none;
      display: none !important;
    }
  }
  
  .icon {
    margin-left: 6px;
  }
}